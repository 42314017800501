<template>
  <div>
    <SectionHeading
      :content="heading.content"
      :extra_content="heading.extra"
      @search="onSearch"
      @btnClick="onNewRowClick"
    />
    <Table
      :search="search"
      :headers="headers"
      :items="getFormatRows"
      :bannable="true"
      @edit="onRowEdit"
      @ban="onRowBan"
      @remove="onRowRemove"
    />

    <v-dialog v-model="banDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Ban this user </v-card-title>
        <v-card-text>
          <v-text-field label="Ban reason" v-model="entry.ban_reason" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog"> Cancel </v-btn>
          <v-btn text @click="saveBanData"> Ban </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="rmDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Remove this row? </v-card-title>
        <v-card-text>Are you sure you want to remove this row?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog"> No </v-btn>
          <v-btn text @click="saveRmData"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="450" v-model="addDialog">
      <v-card>
        <v-card-title>{{ dialogHeader }}</v-card-title>

        <v-divider />

        <v-card-text>
          <v-form class="mt-5">
            <v-text-field label="E-mail Address" v-model="entry.email" />
            <v-text-field
              :disabled="true"
              label="Username"
              v-model="entry.username"
            />
            <v-text-field label="API Key" v-model="entry.api_key" />

            <v-text-field
              label="Subscription Plan ID"
              v-model="entry.plan_id"
            />

            <v-dialog
              ref="dialog"
              v-model="entry.datePicker"
              :return-value.sync="entry.date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="entry.date"
                  label="Subscription Expiry Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="entry.date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="entry.datePicker = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="pickDate(entry.date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-select
              label="Role"
              :items="[
                {
                  name: 'Admin',
                  value: 1,
                },

                {
                  name: 'User',
                  value: 0,
                },
              ]"
              item-text="name"
              item-value="value"
              v-model="entry.admin"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="closeDialog" text> Cancel </v-btn>
          <v-btn @click="saveEditData" color="primary" text>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SectionHeading from "../../components/SectionHeading.vue";
import Table from "../components/Table.vue";
export default {
  name: "Users",

  components: {
    Table,
    SectionHeading,
  },

  async mounted() {
    let fetched = await this.$http
      .get("/admin/customer/all")
      .then((res) => res.data);

    this.rows = fetched.users;
  },

  computed: {
    getFormatRows() {
      let cpyRows = JSON.parse(JSON.stringify(this.rows));
      return cpyRows.map((e) => {
        return Object.assign(e, {
          admin: e.admin == 1 ? "Admin" : "User",
          banned: e.banned != 0 ? e.ban_reason : "-",
          plan_expiry:
            e.plan_expiry == 0
              ? "No active plan"
              : Math.floor(
                  (e.plan_expiry * 1000 - Date.now()) / 1000 / 60 / 60 / 24
                ) + " days left",
          last_login: new Date(e.last_login * 1000).toLocaleDateString(),
          verified: e.verified == 1 ? "Yes" : "No",
        });
      });
    },
  },

  methods: {
    async saveBanData() {
      this.entry.banned = 1;
      if(!this.entry.ban_reason) this.entry.ban_reason = 'Banned by the Administrator';
      
      try {
        await this.$http
          .post("/admin/customer/edit", this.entry)
          .then((res) => res.data);
        this.$dtoast.pop({
          preset: "success",
          heading: `Success!`,
          content: "Your changes were saved",
        });
      } catch (err) {
        this.$dtoast.pop({
          preset: "error",
          heading: `Ooops!`,
          content: "Couldn't process your request",
        });
      }

      this.closeDialog();
    },

    async saveRmData() {
      try {
        await this.$http
          .post("/admin/customer/delete", { username: this.entry.username })
          .then((res) => res.data);
        this.$dtoast.pop({
          preset: "success",
          heading: `Success!`,
          content: "Your changes were saved",
        });
      } catch (err) {
        this.$dtoast.pop({
          preset: "error",
          heading: `Ooops!`,
          content: "Couldn't process your request",
        });
      }

      this.closeDialog();
    },

    async saveEditData() {
      try {
        await this.$http
          .post("/admin/customer/edit", this.entry)
          .then((res) => res.data);
        this.$dtoast.pop({
          preset: "success",
          heading: `Success!`,
          content: "Your changes were saved",
        });
      } catch (err) {
        this.$dtoast.pop({
          preset: "error",
          heading: `Ooops!`,
          content: "Couldn't process your request",
        });
      }

      this.closeDialog();
    },

    pickDate(date) {
      this.$refs.dialog.save(date);

      this.entry.plan_expiry = Math.floor(new Date(date).getTime() / 1000);
    },

    closeDialog() {
      this.addDialog = false;
      this.rmDialog = false;
      this.banDialog = false;

      this.entry = {};
    },

    onSearch(e) {
      this.search = e;
    },

    onNewRowClick() {
      this.dialogHeader = "Create User";
      this.addDialog = true;
    },

    onRowEdit(id) {
      id -= 1;
      this.dialogHeader = "Edit User";
      this.entry = this.rows[id];
      this.addDialog = true;
    },

    onRowRemove(id) {
      id -= 1;
      this.rmDialog = true;
      this.entry = this.rows[id];
    },

    onRowBan(id) {
      id -= 1;
      this.entry = this.rows[id];
      this.banDialog = true;
    },
  },

  data() {
    return {
      datePickerOpened: false,

      banDialog: false,
      rmDialog: false,
      addDialog: false,
      dialogHeader: null,
      deleteDialog: false,

      entry: {},

      heading: {
        content: "Users",
        extra: [{ type: "search" }],
      },

      search: "",

      headers: [
        {
          text: "E-mail",
          align: "start",
          value: "email",
        },
        { text: "Username", value: "username" },
        { text: "API", value: "api_key" },
        { text: "Banned", value: "banned" },
        { text: "Subscription", value: "plan_expiry" },
        { text: "Last Login", value: "last_login" },
        { text: "Verified", value: "verified" },
        { text: "Role", value: "admin" },
        { text: "Actions", value: "actions" },
      ],
      rows: [],
    };
  },
};
</script>

<style lang='scss'>
</style>